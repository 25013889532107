<template>
  <v-container>
    <v-row>
      <v-col cols="12"
        md="11">
        <h1>Jobs Management</h1>
      </v-col>
    </v-row>
    <v-card class="mt-5">
      <v-card-title>Create Jobs By GeoFence</v-card-title>
      <v-divider />
      <v-card-text>
        <load-job :job-info.sync="jobData" />
        <job-detail :job-info.sync="jobData" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <btn-job :job-info.sync="jobData" />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'JobByGeoFence',

  components: {
    LoadJob: () => import('@/components/jobs/singleByGeoFrencing.vue'),
    JobDetail: () => import('@/components/jobs/jobDetail.vue'),
    BtnJob: () => import('@/components/jobs/jobGeoBotton.vue')
  },

  data: () => ({
    jobData: {
      name: '',
      owner: '',
      totalEstimateIds: 0,
      startTime: 0,
      endTime: 0,
      titleTh: '',
      titleEn: '',
      messageTh: '',
      messageEn: '',
      category: '',
      contentType: '',
      deepLinkLiteTh: '',
      deepLinkLiteEn: '',
      deepLink: '',
      imageIdLiteTh: '',
      imageId: '',
      optionKey: '',
      optionValue: '',
      sound: '',
      delay: 0,
      maxPushPerJob: 0,
      allowOverridePushMessageTime: false,
      allowOverrideQuotaLimitPerDay: false,
      location: {
        name: '',
        radius: 1,
        type: 'Polygon',
        coordinates: []
      },
      ids: [],
      msisdn: '',
      longitude: 0,
      latitude: 0,
      activated: true,
      api: '',
      filterDevice: ''
    }
  }),

  computed: {
    ...mapGetters({
      messageSuccess: 'getSuccess'
    })
  },

  async created() {
    this.clearMessage();
    // eslint-disable-next-line no-undef
    eventBus.$on('createGeo', data => this.createGeo(data));
    
    // eslint-disable-next-line no-undef
    eventBus.$on('createJobTestGeo', data => this.createJobTestGeo(data));
  },

  beforeDestroy() {
    // eslint-disable-next-line no-undef
    eventBus.$off('createGeo');

    // eslint-disable-next-line no-undef
    eventBus.$off('createJobTestGeo');
  },

  methods: {
    clearMessage() {
      this.$store.dispatch('Clear_Message_Status');
    },

    init() {
      this.jobData.name = '';
      this.jobData.owner = '';
      this.jobData.totalEstimateIds = 0;
      this.jobData.startTime = 0;
      this.jobData.endTime =  0;
      this.jobData.titleTh = '';
      this.jobData.titleEn = '';
      this.jobData.messageTh = '';
      this.jobData.messageEn = '';
      this.jobData.category = '';
      this.jobData.contentType = '';
      this.jobData.deepLinkLiteTh = '';
      this.jobData.deepLinkLiteEn = '';
      this.jobData.deepLink = '';
      this.jobData.imageIdLiteTh = '';
      this.jobData.imageId = '';
      this.jobData.optionKey = '';
      this.jobData.optionValue = '';
      this.jobData.sound = '';
      this.jobData.delay = 0;
      this.jobData.ids ='';
      this.jobData.msisdn = '';
      this.jobData.maxPushPerJob = 0;
      this.jobData.api='';
      this.allowOverridePushMessageTime = false;
      this.allowOverrideQuotaLimitPerDay = false;
      this.location.name = '';
      this.location.coordinates = [];
      this.longitude = 0;
      this.latitude = 0;
      this.activated = true;
      this.jobData.filterDevice='';
    },

    async createGeo() {
      this.jobData.totalEstimateIds = this.jobData.msisdn.length;

      await this.$store.dispatch('jobManagement/CREATE_JOB_GEOFENCE', this.jobData)
        .then(() => {
          if (this.messageSuccess.trim() === 'Create Job success') {
            this.init();
            this.clearMessage();
            this.$router.push({ name: 'automateCondition' })
          }
        })
    },

    async createJobTestGeo() {
    this.jobData.totalEstimateIds = this.jobData.msisdn.length;
      await this.$store.dispatch('jobManagement/CREATE_JOB_TEST_GEO', this.jobData)
        .then(() => {
          if (this.messageSuccess.trim() === 'Create Job Test success') {
            this.clearMessage();
          }
        })
    }
  }
}
</script>